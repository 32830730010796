var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c(
        "el-container",
        { staticClass: "flex-column" },
        [
          _c(
            "el-container",
            { staticClass: "flex-row flex-item" },
            [_c("app-main")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }