import { render, staticRenderFns } from "./EmptyLayout.vue?vue&type=template&id=a3a14c28&scoped=true"
import script from "./EmptyLayout.vue?vue&type=script&lang=js"
export * from "./EmptyLayout.vue?vue&type=script&lang=js"
import style0 from "./EmptyLayout.vue?vue&type=style&index=0&id=a3a14c28&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3a14c28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\work\\4、hdyb青岛华德相关项目\\hsmanagementsystem-华水管理系统PC\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3a14c28')) {
      api.createRecord('a3a14c28', component.options)
    } else {
      api.reload('a3a14c28', component.options)
    }
    module.hot.accept("./EmptyLayout.vue?vue&type=template&id=a3a14c28&scoped=true", function () {
      api.rerender('a3a14c28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/EmptyLayout.vue"
export default component.exports